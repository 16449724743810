<template>
  <div>
    <div class="loading" v-show="loadingSohw">
      <van-loading type="spinner" color="#0E3592">加载中...</van-loading>
    </div>
    <div class="personal" v-show="pageShow">
      <div class="top_bar">
        <div>个人中心</div>
      </div>
      <div class="top" :style="{ 'background-image': `url(${bgimg})` }">
        <div class="top_left">
          <van-image
            round
            @click="$refs.avatarInp.click()"
            fit="cover"
            :src="avatar"
            class="seting_header_img fl"
          />
          <input
            type="file"
            id="avatarInp"
            name
            accept="image/*"
            @change="handelChangeAvatar"
            ref="avatarInp"
            style="display: none"
          />
        </div>
        <div v-if="userinfo.user_type == 'agent'" class="top_right">
          <div class="right_name">
            <div class="left">
              <div class="teamName">
                {{
                  userinfo.nick_name ? userinfo.nick_name : userinfo.nickname
                }}
              </div>
              <div class="teamLevel">
                <img
                  :src="
                    userinfo.dl_level_now == 0
                      ? boss
                      : userinfo.dl_level_now == 1
                      ? one
                      : userinfo.dl_level_now == 2
                      ? two
                      : userinfo.dl_level_now == 3
                      ? three
                      : putong
                  "
                  alt
                />
                <span>{{ userinfo.dl_name_now }}</span>
              </div>
            </div>
            <div class="teamColor">{{ userinfo.team_id }}</div>
          </div>
          <div class="id">
            <div class="right_id">团队ID：{{ userinfo.team_num }}</div>
          </div>
          <div class="right_phone">手机号：{{ userinfo.mobile }}</div>
        </div>

        <div v-else class="top_right">
          <div class="customer_right_name">
            <div class="teamName">
              {{ userinfo.nick_name ? userinfo.nick_name : userinfo.nickname }}
            </div>
            <!-- <div class="teamColor">{{userinfo.team_id}}</div> -->
          </div>
          <!-- <div  class="id">
          <div class="right_id">团队ID：{{userinfo.team_num}}</div>
        </div> -->
          <div class="customer_right_phone">
            手机号：{{ userinfo.mobile == "0" ? "" : userinfo.mobile }}
          </div>
          <!-- <div class="right_level">
          <span class="level_title">等级：</span>
          <img
            :src="userinfo.dl_level_now==0?boss:userinfo.dl_level_now==1?one:userinfo.dl_level_now==2?two:userinfo.dl_level_now==3?three:putong"
            alt
          />
          <span>{{userinfo.dl_name_now}}</span>
        </div> -->
        </div>
      </div>
      <!-- 积分 -->
      <div v-if="userinfo.user_type == 'agent'" class="integral">
        <div
          class="integral_left"
          style="width: 100%; justify-content: space-around"
        >
          <div class="left_left">
            <div class="left_text">
              <img style="" src="../../assets/image/money1.png" alt />
              <span>分发积分</span>
            </div>
            <div class="left_num">
              <span>{{ userinfo.ff_money }}</span>
            </div>
            <div class="left_bottom" @click="handleItem(7)">规则</div>
          </div>
          <div class="left_right">
            <div class="right_text">
              <img style="" src="../../assets/image/money2.png" alt />
              <span>订货积分</span>
            </div>
            <div class="right_num">
              <span>{{ userinfo.dh_money }}</span>
            </div>
            <div class="left_bottom" @click="show = true">申请</div>
          </div>
        </div>
        <!-- <div class="integral_right" v-if="is_show==1">
        <div>佣金余额</div>
        <div class="nums">
          <span>{{userinfo.distribut_money}}</span>金币
        </div>
        <div class="right_bottom">
          <div @click="handleItem(8)">规则</div>
          <div @click="handleItem(0)">申请提现>></div>
        </div>
      </div> -->
      </div>

      <div v-else class="customer_integral">
        <div
          class="integral_left"
          style="width: 100%; justify-content: space-around"
        >
          <div class="left_left">
            <div class="left_text">
              <img
                style=""
                src="../../assets/image/money1.png"
                alt
              />
              <span>我的余额</span>
            </div>
            <div class="left_num">
              <span>{{ userinfo.ff_money }}</span>
            </div>
            <!-- <div class="left_bottom" @click="handleItem(7)">规则</div> -->
          </div>
          <div class="left_right">
            <div class="right_text">
              <img
                style=""
                src="../../assets/image/money2.png"
                alt
              />
              <span>我的积分</span>
            </div>
            <div class="right_num">
              <span>{{ userinfo.dh_money }}</span>
            </div>
            <!-- <div class="left_bottom" @click="show = true">申请积分>></div> -->
          </div>
        </div>
        <!-- <div class="integral_right" v-if="is_show==1">
        <div>佣金余额</div>
        <div class="nums">
          <span>{{userinfo.distribut_money}}</span>金币
        </div>
        <div class="right_bottom">
          <div @click="handleItem(8)">规则</div>
          <div @click="handleItem(0)">申请提现>></div>
        </div>
      </div> -->
      </div>

      <!-- 分类 -->
      <div class="classify">
        <div v-if="userinfo.user_type == 'agent'" class="class_top">
          <div class="top_item item_one" @click="handleItem(1)">
            <div>
              <img src="../../assets/image/icon_hzs.png" alt />
            </div>
            <div>我的合作商</div>
          </div>
          <div class="top_item" @click="handleItem(2)">
            <div>
              <img src="../../assets/image/dd.png" alt />
            </div>
            <div>订单</div>
          </div>
          <div class="top_item item_three" @click="handleItem(3)">
            <div>
              <img src="../../assets/image/xjdd.png" alt />
            </div>
            <div>发货订单</div>
          </div>
        </div>

        <div v-else class="class_top">
          <div class="top_item item_one" @click="handleItem(9)">
            <div>
              <img src="../../assets/image/tgmp.png" alt />
            </div>
            <div>地址</div>
          </div>
          <div class="top_item" @click="handleItem(10)">
            <div>
              <img src="../../assets/image/dd.png" alt />
            </div>
            <div>订单</div>
          </div>
          <div class="top_item item_three" @click="handleItem(11)">
            <div>
              <img src="../../assets/image/xjdd.png" alt />
            </div>
            <div>优惠券</div>
          </div>
        </div>

        <div v-if="userinfo.user_type == 'agent'" class="class_bottom">
          <div class="bottom_item item_one" @click="handleItem(4)">
            <div>
              <img src="../../assets/image/tgmp.png" alt />
            </div>
            <div>推广名片</div>
          </div>
          <div class="bottom_item">
            <div>
              <img
                src="../../assets/image/fpsq_m.png"
                alt
                @click="handleItem(5)"
              />
            </div>
            <div>发票申请</div>
          </div>
          <div class="bottom_item item_three" @click="handleItem(6)">
            <div>
              <img src="../../assets/image/lx.png" alt />
            </div>
            <div>联系我们</div>
          </div>
        </div>

        <div v-else class="class_bottom">
          <div class="bottom_item item_one" @click="handleItem(12)">
            <div>
              <img src="../../assets/image/icon_hzs.png" alt />
            </div>
            <div>申请经销商</div>
          </div>
          <div class="bottom_item">
            <div>
              <img
                src="../../assets/image/fpsq_m.png"
                alt
                @click="handleItem(5)"
              />
            </div>
            <div>发票申请</div>
          </div>
          <div class="bottom_item item_three" @click="handleItem(6)">
            <div>
              <img src="../../assets/image/lx.png" alt />
            </div>
            <div>联系我们</div>
          </div>
        </div>
        <!-- 竖线 -->
        <div class="line"></div>
        <div class="line_two"></div>
      </div>
      <!-- 二维码 -->
      <div class="code">
        <div>
          <img :src="codeImg" alt />
        </div>
        <div>关注公众号</div>
      </div>
      <!-- 弹框 -->
      <van-overlay :show="show" @click="show = false">
        <div class="exchange_popup">
          <div class="popup_img" @click="show = false">
            <img src="../../assets/image/close.svg" alt />
          </div>
          <div class="popup_content" @click.stop>
            <div class="popup_title">上级代理商</div>
            <div class="popup_name">
              <span class="popup_name_left">代理商姓名：</span>
              <span>{{ message.name }}</span>
            </div>
            <div class="popup_phone">
              <span>联系电话：</span>
              <span class="popup_phone_right">{{ message.phone }}</span>
            </div>
            <a class="btn btn_w" :href="'tel:' + message.phone">申请积分</a>
          </div>
        </div>
      </van-overlay>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      is_show: 1,
      userinfo: {},
      boss: require("../../assets/image/zongdai.png"),
      putong: require("../../assets/image/putong.png"),
      one: require("../../assets/image/one.png"),
      two: require("../../assets/image/two.png"),
      three: require("../../assets/image/three.png"),
      codeImg: "",
      message: {},
      bgimg: "",
      avatar: "",
      loadingSohw: true,
      pageShow: false,
    };
  },
  methods: {
    handleItem(type) {
      if (type == 0) {
        this.$router.push({
          path: "/commission/index",
        });
      } else if (type == 1) {
        this.$router.push({
          path: "/dealers/index",
        });
      } else if (type == 2) {
        this.$router.push({
          path: "/order/index",
        });
      } else if (type == 3) {
        this.$router.push({
          path: "/levelOrder/index",
        });
      } else if (type == 4) {
        if (
          this.userinfo.first_leader == 0 &&
          this.userinfo.dl_level_now != 1
        ) {
          this.$notify({
            type: "danger",
            message: "您没有通过二维码认证，请咨询您所属区域的总代理!",
          });
          return;
        } else {
          this.$router.push({
            path: "/posters/index",
          });
        }
      } else if (type == 5) {
        this.$router.push({
          path: "/invoice/index",
        });
      } else if (type == 6) {
        this.$router.push({
          path: "/call/index",
        });
      } else if (type == 7) {
        this.$router.push({
          path: "/rules/index",
          query: {
            type: 1,
          },
        });
      } else if (type == 8) {
        this.$router.push({
          path: "/rules/index",
          query: {
            type: 2,
          },
        });
      } else if (type == 9) {
        this.$router.push({
          path: "/Address/index",
        });
      } else if (type == 10) {
        this.$router.push({
          path: "/customerOrder/index",
        });
      } else if (type == 11) {
        this.$notify({ type: "danger", message: "开发中,敬请期待" });
        return;
      } else if (type == 12) {
        this.$router.push({
          path: "/applyAgent/index",
        });
      }
    },
    async handleuserinfo() {
      const data = await this.$api.userinfo();
      console.log(this.loadingSohw, "xxx");
      if (data.data.code === 1) {
        this.userinfo = data.data.data;
        this.avatar = data.data.data.head_pic;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
      this.loadingSohw = false;
      this.pageShow = true;
      console.log(this.loadingSohw, "qqq");
    },
    async handleGetPhone() {
      const data = await this.$api.getPhone({
        // goods_id: this.goods_id
      });
      if (data.data.code === 1) {
        this.message = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handleAboutUs() {
      const data = await this.$api.aboutUs();
      console.log(data, ",handleAboutUs,,data");
      if (data.data.code === 1) {
        this.codeImg = data.data.data.mark_img;
        this.is_show = data.data.data.is_show;
        this.bgimg = data.data.data.background_img;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handelChangeAvatar(event) {
      // console.log(event.target.files[0]);
      // 根据参数传值
      const parama = new FormData();
      parama.append("file", event.target.files[0]);
      const data = await this.$api.uploadImg(parama);
      // console.log(data, ",,,data");
      if (data.data.code === 1) {
        this.handleuserinfo();
      }

      // console.log(data,"data")
    },
  },
  mounted() {
    if (sessionStorage.getItem("mobile_validated") == 0) {
      this.$router.push({
        path: "/login",
      });
    } else if (sessionStorage.getItem("info_validated") == 0) {
      this.$router.push({
        path: "/information",
      });
    }
    this.handleuserinfo();
    this.handleGetPhone();
    this.handleAboutUs();
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  padding-top: 75%;
  padding-left: 45%;
}
.personal {
  width: 375px;
  background: rgba(245, 245, 245, 1);
  height: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
  padding-top: 40px;
  box-sizing: border-box;
  position: relative;
  .top {
    width: 375px;
    height: 201px;
    // background-image: url("../../assets/image/per_bac.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 20px;
    padding-left: 15px;
    box-sizing: border-box;
    display: flex;
    .top_left {
      width: 80px;
      height: 80px;
      background: rgba(255, 255, 255, 0.72);
      border-radius: 50%;
      text-align: center;
      margin-right: 19px;
      .seting_header_img {
        width: 82px;
        height: 82px;
      }
    }

    .top_right {
      .right_name {
        width: 250px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: white;
        line-height: 18px;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;

        .teamColor {
          color: white;
          max-width: 20%;
        }
        .left {
          display: flex;
          max-width: 80%;
          .teamName {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 10px;
          }
          .teamLevel {
            img {
              width: 15px;
              height: 15px;
              margin-right: 3px;
            }
            .level_title {
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: black;
              line-height: 13px;
            }
          }
        }
      }
      .customer_right_name {
        margin-top: 10px;
        width: 250px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: white;
        line-height: 18px;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        .teamName {
          max-width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 21px;
        }
      }

      .id {
        // display: flex;
        .right_id {
          font-size: 11px;
          color: white;
          line-height: 12px;
          margin-top: 10px;
        }
      }
      .right_phone {
        font-size: 11px;
        color: white;
        line-height: 12px;
        margin-top: 10px;
      }
      .customer_right_phone {
        font-size: 11px;
        color: white;
        line-height: 12px;
        margin-top: 10px;
      }
      .right_level {
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: white;
        line-height: 13px;
        margin-top: 16px;
        img {
          width: 15px;
          height: 15px;
          margin-right: 3px;
        }
        .level_title {
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: black;
          line-height: 13px;
        }
      }
    }
  }
  .customer_integral {
    width: 351px;
    height: 130px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    position: absolute;
    left: 12px;
    top: 181px;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    box-sizing: border-box;
    .integral_left {
      height: 99px;
      width: 50%;
      border-right: 1px solid #eaeaea;
      font-size: 12px;
      color: #333;
      display: flex;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 19px;
      box-sizing: border-box;
      .left_left {
        line-height: 30px;
        .left_text {
          display: flex;
          align-items: center;
          img {
            width: 29px;
          }
        }
        .left_num {
          height: 76px;
          line-height: 5px;
          padding-top: 30px;
          box-sizing: border-box;
          text-align: center;
          span {
            font-size: 15px;
            color: #fe1d1d;
          }
        }
        .left_bottom {
          font-size: 10px;
          color: #0e3592;
          line-height: 10px;
        }
      }
      .left_right {
        line-height: 30px;
        .right_text {
          display: flex;
          align-items: center;
          img {
            width: 29px;
          }
        }
        .right_num {
          height: 76px;
          line-height: 5px;
          padding-top: 30px;
          box-sizing: border-box;
          text-align: center;
          span {
            font-size: 15px;
            color: #0e3592;
          }
        }
        .left_bottom {
          font-size: 10px;
          color: #0e3592;
          line-height: 10px;
        }
      }
    }
    .integral_right {
      width: 50%;
      font-size: 12px;
      color: rgba(51, 51, 51, 1);
      line-height: 12px;
      padding: 0 21px;
      box-sizing: border-box;
      text-align: center;
      .nums {
        margin-top: 30px;
        line-height: 20px;
        span {
          font-size: 15px;
          color: #fe1d1d;
        }
      }
      .right_bottom {
        display: flex;
        justify-content: space-between;
        font-size: 10px;
        color: #0e3592;
        line-height: 10px;
        margin-top: 26px;
      }
    }
  }
  .integral {
    width: 351px;
    height: 139px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    position: absolute;
    left: 12px;
    top: 181px;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    box-sizing: border-box;
    .integral_left {
      height: 99px;
      width: 50%;
      border-right: 1px solid #eaeaea;
      font-size: 12px;
      color: #333;
      display: flex;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 19px;
      box-sizing: border-box;
      .left_left {
        line-height: 12px;
        .left_text {
          display: flex;
          align-items: center;
          img {
            width: 29px;
          }
        }
        .left_num {
          height: 76px;
          line-height: 5px;
          padding-top: 30px;
          box-sizing: border-box;
          text-align: center;
          span {
            font-size: 15px;
            color: #fe1d1d;
          }
        }
        .left_bottom {
          font-size: 10px;
          color: #0e3592;
          line-height: 5px;
          text-align: center;
        }
      }
      .left_right {
        line-height: 12px;
        .right_text {
          display: flex;
          align-items: center;
          img {
            width: 29px;
          }
        }
        .right_num {
          height: 76px;
          line-height: 5px;
          padding-top: 30px;
          box-sizing: border-box;
          text-align: center;
          span {
            font-size: 15px;
            color: #0e3592;
          }
        }
        .left_bottom {
          font-size: 10px;
          color: #0e3592;
          line-height: 5px;
          text-align: center;
        }
      }
    }
    .integral_right {
      width: 50%;
      font-size: 12px;
      color: rgba(51, 51, 51, 1);
      line-height: 12px;
      padding: 0 21px;
      box-sizing: border-box;
      text-align: center;
      .nums {
        margin-top: 30px;
        line-height: 20px;
        span {
          font-size: 15px;
          color: #fe1d1d;
        }
      }
      .right_bottom {
        display: flex;
        justify-content: space-between;
        font-size: 10px;
        color: #0e3592;
        line-height: 10px;
        margin-top: 26px;
      }
    }
  }
  .classify {
    width: 351px;
    height: 180px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 90px;
    position: relative;
    .class_top {
      width: 321px;
      height: 84px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px dotted #efefef;
      font-size: 12px;
      color: rgba(78, 78, 78, 1);
      line-height: 12px;
      align-items: center;
      .top_item {
        width: calc(100% / 3);
        text-align: center;
        div {
          img {
            width: 50px;
            height: 50px;
            margin-bottom: 12px;
          }
        }
      }
      .item_one {
        // padding-right: 36px;
        padding-right: 15px;
        box-sizing: border-box;
      }
      .item_three {
        // padding-left: 48px;
        padding-left: 15px;
        box-sizing: border-box;
      }
    }
    .class_bottom {
      width: 321px;
      height: 94px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: rgba(78, 78, 78, 1);
      line-height: 12px;
      align-items: center;
      .bottom_item {
        text-align: center;
        width: calc(100% / 3);
        div {
          img {
            width: 50px;
            height: 50px;
            margin-bottom: 10px;
          }
        }
      }
      .item_one {
        padding-right: 15px;
        // padding-right: 36px;
        box-sizing: border-box;
      }
      .item_three {
        // padding-left: 48px;
        padding-left: 15px;
        box-sizing: border-box;
      }
    }
    .line {
      position: absolute;
      height: 130px;
      border-right: 1px dotted #efefef;
      top: 10px;
      left: 116px;
    }
    .line_two {
      position: absolute;
      height: 130px;
      border-right: 1px dotted #efefef;
      top: 10px;
      right: 116px;
    }
  }
  .code {
    width: 100%;
    text-align: center;
    margin-top: 15px;
    font-size: 13px;
    color: rgba(14, 14, 14, 1);
    line-height: 13px;
    margin-bottom: 13px;
    img {
      width: 101px;
      height: 100px;
      margin-bottom: 15px;
    }
  }
  .exchange_popup {
    position: relative;
    .popup_img {
      width: 22px;
      height: 22px;
      position: absolute;
      top: 104px;
      right: 32px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .popup_content {
      width: 310px;
      height: 250px;
      background: rgba(255, 255, 255, 1);
      border-radius: 6px;
      position: absolute;
      top: 136px;
      right: 32px;
      padding-top: 20px;
      box-sizing: border-box;
      .popup_title {
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
      }
      .popup_name {
        margin-top: 42px;
        line-height: 22px;
        font-size: 16px;
        color: #333;
        padding-left: 66px;
        box-sizing: border-box;
        .popup_name_left {
          color: #999;
        }
      }
      .popup_phone {
        font-size: 16px;
        color: rgba(153, 153, 153, 1);
        line-height: 22px;
        margin-top: 25px;
        padding-left: 66px;
        box-sizing: border-box;
        .popup_phone_right {
          color: #327dfb;
        }
      }

      .btn_w {
        position: absolute;
        width: 210px;
        bottom: 26px;
        left: 50px;
      }
    }
    .content_h {
      height: 210px;
    }
  }
}
</style>
